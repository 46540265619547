/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// const cypressRemoteUrl = (process.env.VUE_APP_STAGING_URL && process.env.VUE_APP_STAGING_URL !== 'none') ? process.env.VUE_APP_STAGING_URL : 'none'

window.XPEDEO_SETTINGS = {
  content: {
    // remoteUrl: cypressRemoteUrl !== 'none' ? cypressRemoteUrl : ((process.env.NODE_ENV !== 'development' && (process.env.VUE_APP_IS_LOAN_DEVICE || process.env.VUE_APP_PLATFORM === 'browser')) ? '' : 'https://dev.xpedeo.de/'),
    // remoteUrl: 'https://dev.xpedeo.de/',
    remoteUrl: '',
    useStaticPageFiles: false
  },
  theme: 't-clear-inverse',
  showLocationSettingsWarning: true
}
